import './Footer.scss';
import LogoSlider from '../LogoSlider/LogoSlider';
import ContactForm from '../ContactForm/ContactForm';

const Footer = () => {
	LogoSlider();
	
	const initMap = () => {
		const mapElement = document.getElementById('google-map');
		
		if (mapElement) {
			const center = { lat: 50.744305, lng: 20.612889 };
			
			const map = new google.maps.Map(
				document.getElementById('google-map'),
				{
					center,
					zoom: 17,
				}
			);
			new google.maps.Marker({
				position: center,
				map,
				title: 'Jumar',
				icon: '/images/icons/marker.svg',
				anchor: new google.maps.Point(120, 79),
			});
		}
	};
	
	initMap();
};

export default Footer;
