/* eslint-disable no-unused-expressions */
import './LeftMenu.scss';

export default (() => {
    const leftMenu = document.getElementById('js-left-menu');
    const hasSubmenu = [...leftMenu.getElementsByClassName('has-submenu')];
    const searchInput = document.getElementById('js-search');
    const menuElems = [
        ...leftMenu.getElementsByClassName('left-menu__navigation-link'),
        ...leftMenu.getElementsByClassName('left-menu__submenu-link'),
    ];
    const togglers = [
        ...document.getElementsByClassName('js-bottom-menu-toggler'),
    ];

    const makeActive = (elem, showSubmenu) => {
        elem.classList.remove('inactive');
        showSubmenu && elem.classList.add('active');
    };
    const makeInactive = (elem, hideSubmenu) => {
        elem.classList.add('inactive');
        hideSubmenu && elem.classList.remove('active');
    };
    const isInactive = elem => elem.classList.contains('inactive');

    const searchOffer = ({ currentTarget }) => {
        menuElems.map(elem => {
            if (currentTarget.value === '') {
                isInactive(elem) && makeActive(elem);
                elem.classList.contains('left-menu__navigation-link') && elem.classList.remove('active');
    
                return false;
            }
            if (elem.innerText.toLowerCase().includes(currentTarget.value.toLowerCase())) {
                elem.classList.contains('left-menu__navigation-link') && makeActive(elem);
                elem.classList.contains('left-menu__submenu-link') && makeActive(elem.closest('.left-menu__submenu').previousElementSibling, true);
            } else {
                elem.classList.contains('left-menu__navigation-link') && makeInactive(elem);
                elem.classList.contains('left-menu__submenu-link') && makeInactive(elem.closest('.left-menu__submenu').previousElementSibling, true);
            }

            return null;
        });
    };

    const toggleMenu = () => {
        const toggleFreezeBody = () => {
            document.getElementsByTagName('html')[0].classList.toggle('frozen');
        };

        toggleFreezeBody();
        leftMenu.classList.toggle('active');
    };

    const toggleSubmenu = ({ currentTarget }) => {
        currentTarget.classList.toggle('active');
    };

    searchInput.addEventListener('keyup', searchOffer);
    hasSubmenu.map(submenu => submenu.addEventListener('click', toggleSubmenu));
    togglers.map(toggler => toggler.addEventListener('click', toggleMenu));
})();
