import 'owl.carousel.es6';

import './LogoSlider.scss';

const LogoSlider = () => {
    const autoplay = 5000;

    const prev = "<i class='fa fa-angle-left'></i>";
    const next = "<i class='fa fa-angle-right'></i>";

    $(document).ready(function () {
        $('.logo-slider__wrapper').owlCarousel({
            loop: true,
            margin: 0,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            autoplayTimeout: autoplay,
            mouseDrag: true,
           // autoplayHoverPause: true,
            autoplay: true,
            responsiveClass: true,
            navText: [prev, next],
            dots: true,
            nav: false,
            responsive: {
                0: {
                    items: 1,
                },
                576: {
                    items: 3,
                },
                992: {
                    items: 4,
                },
            },
        });
    });
};

export default LogoSlider;
