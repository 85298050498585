import Colorbox from "../../../components/UI/Colorbox/Colorbox";

import Init from '../../../extendable/Init';
import './Default.scss';

import PageHeaader from '../../../components/PageHeader/PageHeader';
import ContactBox from '../../../components/ContactBox/ContactBox';

const Default = () => {
    Colorbox($('.colorbox'), {
        maxWidth: '90%',
        maxHeight: '90%',
        scrolling: false,
        fixed: true,
        title: '',
    });
    
    const handleRealisationsGallery = () => {
        const [filters, galleryItems] = [
            [...document.getElementsByClassName('js-filter-gallery')],
            [...document.getElementsByClassName('js-gallery-item')],
        ];
        
        const filterGallery = ({ currentTarget }) => {
            if (!currentTarget.classList.contains('active')) {
                filters.map(filter => filter.classList.remove('active'));
                if (!currentTarget.classList.contains('js-dep-option')) {
                    currentTarget.classList.add('active');
                }
                
                if (!currentTarget.dataset.gallery) {
                    galleryItems.map(galleryItem =>
                        galleryItem.classList.add('active')
                    );
                } else {
                    galleryItems.map(galleryItem =>
                        galleryItem.dataset.gal ===
                        currentTarget.dataset.gallery
                            ? galleryItem.classList.add('active')
                            : galleryItem.classList.remove('active')
                    );
                }
            }
        };
        
        filters.map(filter => filter.addEventListener('click', filterGallery));
    };
    const handleOfferChoose = () => {
        const dep = document.getElementById('js-dep');
        if (dep) {
            const choose = dep.getElementsByTagName('div')[0];
            const list = dep.getElementsByTagName('ul')[0];
            const listElems = [...dep.getElementsByTagName('li')];
            document.addEventListener('click', () =>
                list.classList.remove('active')
            );
            
            const toggleList = e => {
                e.stopPropagation();
                list.classList.toggle('active');
            };
            
            const changeDep = e => {
                if (!e.currentTarget.classList.contains('active')) {
                    console.log(e.currentTarget.innerText);
                    choose.innerText = e.currentTarget.innerText;
                    listElems.map(elem =>
                        e.currentTarget === elem
                            ? elem.classList.add('active')
                            : elem.classList.remove('active')
                    );
                    e.currentTarget.classList.add('active');
                }
            };
            
            choose.addEventListener('click', toggleList);
            
            listElems.map(elem => elem.addEventListener('click', changeDep));
        }
    };
    
    handleRealisationsGallery();
    handleOfferChoose();
};

Init(Default);
