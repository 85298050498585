import './FlashBox.scss';

const FlashBox = () => {
	const flashBoxes = [...document.querySelectorAll('.js-flashbox')];
	function init() {
		show();
		close();
	}

	let show = () => {
		flashBoxes.forEach(flashBox => {
			flashBox.classList.add('visible');
		});
	}

	let close = () => {
		flashBoxes.forEach(flashBox => {
			let closers = [...flashBox.querySelectorAll('.js-flashbox-close')];

			closers.forEach(closer => {
				closer.addEventListener('click', e => {
					e.preventDefault();

					flashBox.classList.remove('visible');
				});
			});
		})
	}

	init();
};

export default FlashBox;