import AOS from 'aos';
import '../resources/scss/initial.scss';

import topMenu from '../components/Navs/TopMenu/TopMenu';
import Footer from '../components/Footer/Footer';
import FlashBox from '../components/UI/FlashBox/FlashBox';
import ContactForm from '../components/ContactForm/ContactForm';
import LeftMenu from '../components/LeftMenu/LeftMenu';

const fadeLoader = () =>
    setTimeout(() => {
        document.getElementById('preloader').classList.add('closed');
        AOS.init({
            startEvent: 'DOMContentLoaded',
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
            anchorPlacement: 'top-bottom',
        });
    }, 500);

const Init = module => {
    const init = () => {
        fadeLoader();
        topMenu();
        Footer();
        FlashBox();
        module();
    };

    window.addEventListener('load', init);
};

export default Init;
