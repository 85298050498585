import './LangChanger.scss';

export default (() => {
    const langChanger = document.getElementById('js-change-lang');
    const langList = document.getElementById('js-lang-list');

    document.addEventListener('click', e => {
        if (e.target !== langChanger) {
            langList.classList.remove('active');
        }
    });

    const openChanger = e => {
        e.currentTarget.nextElementSibling.classList.toggle('active');
    };

    langChanger.addEventListener('click', openChanger);

})();
