import axios from 'axios';
import './ContactForm.scss';

export default (() => {
	const formSubmit = document.getElementById('js-submit-form');
	const formAction = document.getElementById('js-form-action');
	const formRetry = document.getElementById('js-retry');
	
	const retry = () => {
		formAction.classList.remove('active');
	};
	
	// const bodyFormData = new FormData();
	// bodyFormData.append('userName', 'Fred');
	
	formSubmit.addEventListener('click', e => {
		// console.log('click send');
		// axios({
		// 	method: 'post',
		// 	url: 'sendContact',
		// 	data: bodyFormData,
		// 	headers: {'Content-Type': 'multipart/form-data'}
		// })
		// .then(function (response) {
		// 	//handle success
		// 	console.log(response);
		// })
		// .catch(function (response) {
		// 	//handle error
		// 	console.log(response);
		// });
	});
	
	formRetry.addEventListener('click', retry);
	
	// formSubmit.addEventListener('click', sendForm);
})();
