import './Colorbox.scss';
import 'jquery-colorbox';


const Colorbox = (selector, config) =>{
    
    selector.colorbox(config);
    
}

export default Colorbox;
